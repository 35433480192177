.header-left {
  height: 100%;
  display: flex;
  align-items: center;
  .breadcrumb {
    margin-bottom: 0;
  }
  .agenda {
    a {
      border-radius: 1.75rem;
      i {
        margin-right: 0.75rem;
        @include respond("laptop") {
          margin-right: 0rem;
        }
      }
      @include custommq($max: 93.75rem) {
        font-size: 0;
        border-radius: 0.75rem;
        padding: 0.8125rem 0.9375rem;
        i {
          font-size: 1.25rem;
          margin-right: 0;
        }
      }
    }
    @include respond("phone") {
      display: none;
    }
  }
  .dashboard_bar {
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--secondary);
    @include respond("tab-land") {
      font-size: 2.125rem;
    }
    @include respond("phone-land") {
      display: none;
    }
    &.sub-bar {
      font-size: 1.875rem;
    }
  }
  .search-bar {
    position: relative;
    &.show-result {
      .search-area-result {
        display: block !important;
      }
      .search-area {
        border-radius: 1rem !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
    }
    .search-area {
      width: 18rem;
      border-radius: 2rem;
      background-color: transparent;
      border: 1px solid $primary;
      padding: 0 1rem;
      @include custommq($max: 100rem) {
        width: 18.75rem;
      }
      @include respond("tab-land") {
        display: none;
      }
      .form-control {
        border: 0;
        color: $primary !important;
        background: transparent;
        font-size: 0.875rem;
        font-weight: 400;
        padding-left: 4px;
        @include respond("laptop") {
          height: 3rem;
          //font-size:13px;
        }
      }
      .input-group-text {
        background-color: transparent;
        border: 0;
        padding-right: 0;
        @include respond("laptop") {
          height: 3rem;
        }
      }
    }

    .search-area-result {
      display: none;
      width: 18rem;
      position: fixed;
      padding: 0px 10px;
      margin-bottom: 1rem;
      height: 300px;
      max-height: 300px;
      background: #e5f1fd;
      border: 1px solid $primary;
      border-top: 0;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      overflow-y: auto;
      overflow-x: hidden;
      @include custommq($max: 100rem) {
        width: 18.75rem;
      }
      @include respond("tab-land") {
        display: none;
      }
      .search-area-result-item {
        margin: 1rem 0;
        span {
          color: $primary;
          font-size: 1rem;
          cursor: pointer;
          &:hover {
            font-weight: 700;
          }
        }
      }
      /* For WebKit Browsers (Chrome, Safari) */
      &::-webkit-scrollbar {
        width: 5px; /* Set the width of the scrollbar */
      }
      &::-webkit-scrollbar-thumb {
        background-color: $primary; /* Set the color of the thumb */
        border-radius: 6px; /* Set the border radius of the thumb */
      }
      &::-webkit-scrollbar-track {
        background-color: transparent; /* Set the color of the track */
      }
      /* Optional: Style the scrollbar buttons (arrows) */
      &::-webkit-scrollbar-button {
        background-color: transparent /* Set the color of the buttons */
      }
      /* For Firefox */
      scrollbar-color: #888 #f1f1f1;
    }
  }
}
[data-headerbg="color_4"] {
  .search-area {
    background-color: transparent !important;
  }
}

[data-sidebar-style="compact"] {
  .header-left {
    margin-left: 0;
  }
}
